<template>
  <div class="another-news">
    <div class="another-news__wrapper">
      <Column class="another-news__limiter">
        <Row align="center"
             class="another-news__title-holder"
             justify="between">
          <h2 class="another-news__title">Другие
            {{this.$page.type === 'news' ? 'новости' : 'события'}}</h2>
        </Row>
      </Column>
      <Section :limiter="$viewport.desktop">
        <Row class="another-news__another-news"
             :wrap="$viewport.mobile"
             justify="between">
          <Card :key="item.id"
                :params="item"
                class="another-news__another-new"
                v-for="item in items"/>
        </Row>
      </Section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnotherNews',
  computed: {
    items() {
      let array = [];
      if (this.$page.type === 'news') {
        array = this.$shuffle(this.$news.data);
      } else {
        array = this.$shuffle(this.$events.data);
      }
      return array
        .filter(item => +item.id !== +this.$route.params.id)
        .splice(0, 3);
    },
  },
  created() {
    if (this.$news.data.length === 0) this.$store.dispatch('GET_NEWS_FROM_SERVER');
    if (this.$events.data.length === 0) this.$store.dispatch('GET_EVENTS_FROM_SERVER');
  },
};
</script>
